import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import BuildingsAdd from "@components/buildings/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "buildings.create"
}

const BuildingsAddPage = (props) => {
  return (
  <>
    <Seo title="Add Building" />
      <div className="main-wrapper">
        <Sidebar menuActive="buildings" 
          action="Add"/>
        <BuildingsAdd 
          menu="Add Buildings" 
          parentPath="buildings" 
          pageType="Add"
          additionalParams={AdditionalParams}/>
      </div>
    </>
  )
}

export default BuildingsAddPage;
